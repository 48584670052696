
export default {
  name: "IndexPage",
  data() {
    return {
      title: "انشاء متجر الكتروني احترافي في 3 خطوات لبيع منتجاتك بنجاح",
      description: "انشاء متجر الكتروني احترافي اصبح سهل مع منصة متجرة التي تساعدك في تجهيز متجر إلكتروني فعال في 3 خطوات حتى تبيع منتجاتك بنجاح على الإنترنت دون الحاجة لتعلم البرمجة أو كتابة الأكواد",
      reviews: [
        {
          masses:
            "هذا ثاني متجر وتطبيق سويناه معهم والحمدلله ينافس المواقع المشهورة من حيث التصميم وسرعة التصفح ونطمح للافضل وشكرا لتعاونكم الدائم معنا ودمتم بخير.",
          fullName: "عبدالله محمد الوصابي",
          info: "عبايات اللمسة المميزة",
          pic: "/assets/images/flags/Flags-1.png",
        },
        {
          masses:
            "فريق الدعم الفني أولًا، فريق رائع بشتى النواحي .. خدمة راقيه و مميزة.",
          fullName: "احمد علي",
          info: "متجر دار حروف",
          pic: "/assets/images/flags/Flags-1.png",
        },
        {
          masses:
            "نشكر منصة متجرة على الدعم مستمر بالمتجر والتجاوب مع اي استفسار ، رد ودعم سريع ومنصة ممتازة جدا وسهولة للتواصل مع خدمة العملاء كلمة شكر لا تكفي متجرة.",
          fullName: "محمد خليل",
          info: "متجر بيت الأطفال",
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses:
            "من غير مجامله وشهادة حق وبكل أمانة فريق متجرة متعاونين جداً ومتجاوبين وكنتم أحد اعمدة النجاح بالنسبة لنا ،",
          fullName: "عبدالله محمد الوصابي",
          info: "عبايات اللمسة الجميلة",
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses:
            "منصة جميلة و سهلة وواجهات بسيطة . و ما شاء الله عليكم اي مشكلة تنحل باسرع وقت . فيكم الخير و البركة٫",
          fullName: "تركي الزهراني",
          info: "متجر أسماك الزينة",
          pic: "/assets/images/flags/Flags-2.png",
        },
      ],
    };
  },
  head() {
    return {
      title: this.title,
      description: this.description,
      keywords: 'انشاء متجر الكتروني, تصميم متجر الكتروني, تجهيز متجر الكتروني',
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.description,
        },
        {
          hid: "og:locale", // open graph type
          property: "og:locale",
          content: "ar_AR",
        },
        {
          hid: "og:type", // open graph type
          property: "og:type",
          content: "article",
        },
        {
          hid: "og:title", // open graph title
          property: "og:title",
          content: this.title,
        },
        {
          hid: "og:description", // open graph description
          property: "og:description",
          content: this.description,
        },
        {
          hid: "og:url", // open graph url
          property: "og:url",
          content: "https://matjrah.com",
        },
        {
          hid: "og:site_name", // open graph site_name
          property: "og:site_name",
          content: "متجرة",
        },
        {
          hid: "og:updated_time", // open graph updated_time
          property: "og:updated_time",
          content: "2022-12-10T00:08:47+03:00",
        },
        {
          hid: "og:image", // open graph image
          property: "og:image",
          content:
            "https://matjrah.com/matjrah.png",
        },
        {
          hid: "apple-mobile-web-app-title", // open graph mobile web app title
          name: "apple-mobile-web-app-title",
          content: this.title,
        },

        // open graph twitter
        { name: "twitter:site", content: "@Matjrah" },
        { name: "twitter:card", content: "summary_large_image" },
        {
          hid: "twitter:url",
          name: "twitter:url",
          content: "https://matjrah.com",
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: this.title,
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content: this.description,
        },
        {
          hid: "twitter:image",
          name: "twitter:image",
          content:
            "https://matjrah.com/matjrah.png",
        },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: "https://matjrah.com/",
        },
        {
          rel: "next",
          href: "https://matjrah.com/prices",
        },
      ],
    };
  },
  jsonld() {
    const items = this.reviews.map((item) => ({
      "@type": "Review",
      "author": { "@type": "Person", "name": item.fullName },
      "datePublished": "2022-01-01",
      "name": item.fullName,
      "reviewBody": item.masses,
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5"
      },
    }));
    return {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebSite",
          "@id": "https://matjrah.com#website",
          "author": { '@id': '#identity' },
          "copyrightHolder": { '@id': '#identity' },
          "copyrightYear": '2022-10-14T10:10:00-07:00',
          "creator": { '@id': '#creator' },
          "dateModified": '2022-10-14T10:10:00-07:00',
          "datePublished": '2022-10-14T10:10:00-07:00',
          "description":
            "انشاء متجر الكتروني احترافي اصبح سهل مع منصة متجرة التي تساعدك في الحصول على متجر إلكتروني فعال في 3 خطوات حتى تبيع منتجاتك بنجاح في متجر على الإنترنت دون الحاجة لتعلم البرمجة  أو كتابة الأكواد",
          "headline": "منصة متجرة",
          "image": {
            '@type': 'ImageObject',
            "url": "https://matjrah.com/matjrah.png",
          },
          "inLanguage": 'ar_AR',
          "mainEntityOfPage": "https://matjrah.com/",
          "name": "منصة متجرة",
          "publisher": { '@id': '#creator' },
          "url": "https://matjrah.com/",
        },
        {
          "@type": "Organization",
          "@id": "https://matjrah.com/#organization",
          "url": "https://matjrah.com/",
          "name": "منصة متجرة",
          "alternateName": "منصة متجرة",
          "description":
            "انشاء متجر الكتروني احترافي اصبح سهل مع منصة متجرة التي تساعدك في الحصول على متجر إلكتروني فعال في 3 خطوات حتى تبيع منتجاتك بنجاح في متجر على الإنترنت دون الحاجة لتعلم البرمجة  أو كتابة الأكواد",
          "logo": {
            "@type": "ImageObject",
            "url": "https://matjrah.com/matjrah.png",
          },
          "image": {
            '@type': 'ImageObject',
            "url": "https://matjrah.com/matjrah.png",
          },
          "contactPoint": [
            {
              "@type": "ContactPoint",
              "telephone": "920033179",
              "contactType": "customer service"
            }
          ]
        },
        {
          "@type": "WebPage",
          "mainEntity": {
            "@type": "Book",
            "author": "/",
            "bookFormat": "https://schema.org/Paperback",
            "datePublished": "2022-10-01",
            "image": "https://matjrah.com/matjrah.png",
            "inLanguage": "Arabic",
            "name": "reviews",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": items.length
            },
            "review": items
          }
        }
      ]
    }
  }
};
